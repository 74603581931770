class State {

  constructor() {
    this.playerOneScore = 0;
    this.playerTwoScore = 0;
    this.turnOfPlayer = 1;
    this.double = 0;
    this.allowUndo = 0;
    this.allowFoul = 0;
    this.allowForfeit = 0;

    this.turns = [{
      player : 1,
      events : []
    }];

    this.getGameState();
  }

  currentTurn() {
    return(this.turns[this.turns.length - 1]);
  }

  addEvent(eventType) {
      
    const commands = [ function(obj, ev) { return (obj.addScore(ev)); }, 
                       function(obj, ev) { return (obj.addEndTurn(eventType)); }, 
                       function(obj, ev) { return (obj.addDouble(eventType)); }, 
                       function(obj, ev) { return (obj.addFoul(eventType)); }, 
                       function(obj, ev) { return (obj.addForfeit(eventType)); }, 
                       function(obj, ev) { return (obj.addUndo(eventType)); }, 
                       function() { return(1); } ];

    let ok = 0;
    let cmd;
    while (ok === 0){
      cmd = commands.shift();
      ok = cmd(this, eventType);
    }

    this.allowUndo = this.currentTurn().events.length ? 1 : 0;
    this.allowFoul = this.scoreInCurrentTurn() ? 1 : 0;
    this.allowForfeit = this.currentPlayerScore() ? 1 : 0;
    this.putGameState();

    return this;
  }

  addScore(eventType) {
        
    const validScores = [ '10', '20', '30', '50', '100', '200' ];

    if ( !validScores.includes(eventType) ) {
      return(0);
    }

    this.addTurnEvent(eventType,eventType);
    this.updatePlayerScore();

    return(1);
  }

  addEndTurn(eventType) {

    if ( eventType !== 'End Turn' ) {
      return(0);
    }

    this.addTurnEvent(eventType,0);
    this.changeTurnOfPlayer();
    this.addNewTurn();

    return(1);
  }

  addFoul(eventType) {

    if ( eventType !== 'Foul' || ! this.allowFoul) {
      return(0);
    }

    const score = this.scoreInCurrentTurn();
    this.addTurnEvent(eventType,score * -1);
    this.updatePlayerScore();

    this.changeTurnOfPlayer();
    this.addNewTurn();

    return(1);
  }

  addForfeit(eventType) {

    if ( eventType !== 'Forfeit' || ! this.allowForfeit) {
      return(0);
    }

    let score;
    if (this.turnOfPlayer === 1) {
      score = this.playerOneScore;
    }
    else {
      score = this.playerTwoScore;
    }
    this.addTurnEvent(eventType,score * -1);
    this.updatePlayerScore();

    this.changeTurnOfPlayer();
    this.addNewTurn();

    return(1);
  }

  addDouble(eventType) {
    if ( eventType !== 'Double' ) {
      return(0);
    }

    this.double = this.double ? 0 : 1;
    return 1;
  }

  addUndo(eventType) {
    if ( eventType !== 'Undo' ) {
      return(0);
    }

    const es = this.currentTurn().events;
    if (es.length) {
      const score = es[es.length-1].score;
      if (this.turnOfPlayer === 1) {
        this.playerOneScore = this.playerOneScore - score;
        if (this.playerOneScore<0) {
          this.playerOneScore = 0;
        }
      }
      else {
        this.playerTwoScore = this.playerTwoScore - score;
        if (this.playerTwoScore<0) {
          this.playerTwoScore = 0;
        }
      }
      es.pop();
    }

    return 1;
  }

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  addNewTurn() {
    this.turns.push( {
      player : this.turnOfPlayer,
      events : []
    });

  }

  addTurnEvent(eventType, score) {
    if (score === undefined) {
      score = 0;
    }
    if (this.double) { 
      score = score * 2;
    }
    this.currentTurn().events.push({score:parseInt(score), type:eventType, double:this.double});
    this.double = 0;
  }

  updatePlayerScore() {
     
    const e = this.currentTurn().events;
    const eventScore = e[e.length-1].score;
    if (this.turnOfPlayer === 1) {
      this.playerOneScore = this.playerOneScore + eventScore;
      if (this.playerOneScore<0) {
        this.playerOneScore = 0;
      }
    }
    else {
      this.playerTwoScore = this.playerTwoScore + eventScore;
      if (this.playerTwoScore<0) {
        this.playerTwoScore = 0;
      }
    }
  }

  changeTurnOfPlayer() {
    const n = (this.turnOfPlayer === 1) ? 2 : 1;
    this.turnOfPlayer = n;
    return 1;
  }

  scoreInCurrentTurn() {
    let score = 0;
    const events = this.currentTurn().events;
    for (let i=0; i<events.length; i++){
        score += events[i].score;
    }
    return(score);
  }

  currentPlayerScore() {
    if (this.turnOfPlayer === 1) {
      return this.playerOneScore;
    }
    else {
      return this.playerTwoScore;
    }
  }

  //Storage ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  putGameState() {
    const date = new Date();
    const data = { turns : this.turns,
                   playerOneScore : this.playerOneScore,
                   playerTwoScore : this.playerTwoScore,
                   allowUndo : this.allowUndo,
                   allowFoul : this.allowFoul,
                   allowForfeit : this.allowForfeit,
                   date : date.toUTCString(),
    };
    localStorage.setItem('dgc100.com.barbilliards', JSON.stringify(data));
  }

  getGameState() {
    try{
      const msInWeek = 1000 * 60 * 60 * 24 + 7;
      const data = JSON.parse(localStorage.getItem('dgc100.com.barbilliards'));

      if (typeof data !== 'undefined' && data !== null && data.date) {

        const then = new Date(data.date);
        const now = new Date();
        const diff = Math.abs(now - then);

        if (diff < msInWeek) {
          this.turns = data.turns;
          this.playerOneScore = data.playerOneScore;
          this.playerTwoScore = data.playerTwoScore;
          this.allowUndo = data.allowUndo;
          this.allowFoul = data.allowFoul;
          this.allowForfeit = data.allowForfeit;
        }

      }
    }
    catch {
      console.log('Unable to access local storage');
    }
  }

}

export default State;
