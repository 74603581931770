import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './common.css';

class PlayerTotal extends React.Component {

render () {

  const label = this.props.playerNum === '1' ? "Red" : "White";

  const classes = 'playerTotal';
  return(
    <span className={classes}>
      {label} : {this.props.value}
    </span>
  );
}

}

export default PlayerTotal;
