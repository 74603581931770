import React from 'react';
import './index.css';
import Score from './Score';
import State from './State';
import PlayerTotal from './PlayerTotal';

import 'bootstrap/dist/css/bootstrap.min.css';
import './common.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Scores extends React.Component {

  constructor(props) {
    super(props);
    this.state = { gameState : new State() };
    this.addEvent = this.addEvent.bind(this);
  }

  addEvent(ev, value) {
    this.setState({ gameState : this.state.gameState.addEvent(value) } );
  }

  render() {

    const rowValues = [ '10', '20', '30', '50', '100', '200' ];

    const specialRowValues = [ 'Double', 'Foul', 'Forfeit', 'End Turn', 'Undo', '' ];

    return(
      <Container>
        <Row>
          <Col className="col-md-6"><PlayerTotal 
                 playerNum  = '1'
                 currentPlayer = {this.state.gameState.turnOfPlayer}
                 value = {this.state.gameState.playerOneScore}
               />
          </Col>
          <Col className="col-md-6"><PlayerTotal 
                 playerNum  = '2'
                 currentPlayer = {this.state.gameState.turnOfPlayer}
                 value = {this.state.gameState.playerTwoScore}
               />
          </Col>
        </Row>
        <Row>
          {Array.from({length: 6}).map((item,i) => {return this.renderScore(rowValues[i])})}
        </Row>
        <Row>
          {Array.from({length: 6}).map((item,i) => {return this.renderScore(specialRowValues[i])})}
        </Row>
      </Container>
    );
  }

  renderScore(i) {
    if (i === '') {
      return(this.renderBlank());
    }
    else {
      return(
        <Col className="col-xs-4 col-sm-4 col-md-4">
        <Score
          value = {i}
          addEvent = {this.addEvent}
          double = {this.state.gameState.double}
          allowUndo = {this.state.gameState.allowUndo}
          allowForfeit = {this.state.gameState.allowForfeit}
          allowFoul = {this.state.gameState.allowFoul}
          currentPlayer = {this.state.gameState.turnOfPlayer}
        />
        </Col>
      );
    }
  }

  renderBlank() {
    return(<Col></Col>);
  }


}

export default Scores;
