import React from 'react';
import Col from 'react-bootstrap/Col';
import './common.css';

class Score extends React.Component {

render () {

  let classes = "score ";
  if ( this.props.value === "Double" && this.props.double) {
    classes = classes + "doubleOn ";
  }

  if (this.props.value === "Undo" && !this.props.allowUndo) {
     classes = classes + "noAllowUndo ";
  }

  if (this.props.value === "Forfeit" && !this.props.allowForfeit) {
     classes = classes + "noAllowForfeit ";
  }

  if (this.props.value === "Foul" && !this.props.allowFoul) {
     classes = classes + "noAllowFoul ";
  }

  if (this.props.currentPlayer === 1) {
    classes = classes + "playerOneTotal";
  }
  else {
    classes = classes + "playerTwoTotal";
  }

  return(
    <Col>
      <button 
        className={classes}
        onClick={(ev) => this.props.addEvent(ev, this.props.value)}
      >
	{this.props.value}
      </button>
    </Col>
  );
}

}

export default Score;
